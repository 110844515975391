import React, { useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import { Button } from "./ui/button"
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog"
import { Input } from "./ui/input"
import { Label } from "./ui/label"
import { Textarea } from './ui/textarea';

interface AddGroupModalProps {
  onGroupAdded: () => void;
}

const AddGroupModal: React.FC<AddGroupModalProps> = ({ onGroupAdded }) => {
  const [groupName, setGroupName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      await addDoc(collection(firestore, 'documentGroups'), {
        name: groupName,
        description,
        createdAt: Timestamp.now(),
      });

      onGroupAdded();
      setOpen(false);
      setGroupName('');
      setDescription('');
    } catch (error) {
      console.error('Error adding group:', error);
      setError('Failed to create group');
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className='ml-4' variant="outline">Create new group</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create new group</DialogTitle>
          <DialogDescription>
            Add a new group to your project. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input
                type="text"
                id="name"
                value={groupName}
                className="col-span-3"
                onChange={(e) => setGroupName(e.target.value)}
                required
              />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="description" className="text-right">
              Description
            </Label>
            <Textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                className="col-span-3"
              />
          </div>
        </div>
        <DialogFooter>
            <Button onClick={handleSubmit} type="submit">Save changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddGroupModal;
