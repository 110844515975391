import React from 'react'
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "./ui/sheet"
import { DocumentGroup } from './DocumentGroups'
import { DropdownMenuItem } from './ui/dropdown-menu';
import { View } from 'lucide-react';

interface SheetGroupDetailsProps {
    group: DocumentGroup;
    onSheetDismiss: () => void;
    buttonTitle: string;
}

function SheetGroupDetails({ group, onSheetDismiss, buttonTitle}: SheetGroupDetailsProps) {

    const [open, setOpen] = React.useState(false);
    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <SheetTrigger asChild>
            <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <View className="h-5 w-5" />
            {buttonTitle}
        </DropdownMenuItem>
            </SheetTrigger>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>{group.name}</SheetTitle>
                    <SheetDescription>
                        {group.description}
                    </SheetDescription>
                </SheetHeader>
            </SheetContent>
        </Sheet>
    )
}

export default SheetGroupDetails