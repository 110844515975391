import React, { useState } from 'react';
import { updateDoc, doc } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import { Button } from "./ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog"
import { Input } from "./ui/input"
import { Label } from "./ui/label"
import { Textarea } from './ui/textarea';
import { DocumentGroup } from './DocumentGroups';
import { DropdownMenuItem } from './ui/dropdown-menu';
import { PencilIcon } from 'lucide-react';

interface EditGroupDialogProps {
  documentId: string;
  buttonTitle: string;
  documentGroup: DocumentGroup;
  onGroupUpdated: () => void;
}

export function EditGroupDialog ({ onGroupUpdated, documentId, buttonTitle, documentGroup }: EditGroupDialogProps) {
  const [groupName, setGroupName] = useState(documentGroup.name);
  const [description, setDescription] = useState(documentGroup.description);
  const [error, setError] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
        await updateDoc(doc(firestore, 'documentGroups', documentId), {
            name: groupName,
            description: description,
          });

      onGroupUpdated();
      setOpen(false);
      setGroupName('');
      setDescription('');
    } catch (error) {
      console.error('Error updating group:', error);
      setError('Failed to updated group');
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <PencilIcon className="h-5 w-5" />
            {buttonTitle}
        </DropdownMenuItem>
        </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{buttonTitle}</DialogTitle>
          <DialogDescription>
            Update the group name and description.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input
                type="text"
                id="name"
                value={groupName}
                className="col-span-3"
                onChange={(e) => setGroupName(e.target.value)}
                required
              />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="description" className="text-right">
              Description
            </Label>
            <Textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                className="col-span-3"
              />
          </div>
        </div>
        <DialogFooter>
            <Button onClick={handleSubmit} type="submit">Save changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
