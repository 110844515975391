import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, deleteDoc, doc, addDoc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL, deleteObject, uploadBytes } from 'firebase/storage';
import { firestore, storage } from '../config/firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useAuth } from '../context/AuthContext';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import PDFViewer from './PDFViewer';
import { ExtractedData, extractPDFData } from '../utils/pdfUtils';
import { DocumentIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import toast, { Toaster } from 'react-hot-toast';

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ArrowUpDown, ChevronDown, DownloadIcon, MoreHorizontal } from "lucide-react"

import { Button } from "./ui/button"
import { Checkbox } from "./ui/checkbox"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu"
import { Input } from "./ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table"
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "./ui/breadcrumb"
import { AlertDialogDelete } from './AlertDialogDelete';

export interface Document {
  id: string;
  filename: string;
  size: number;
  uploadedAt: Date;
  url?: string;
  storageRef: string;
  metadata?: ExtractedData;
}

const interfaceMappings: { [key: string]: string } = {
  "metadata.referenceNumber": "Reference",
  "metadata.clientName": "Client Name",
  "metadata.amount": "Amount",
  "metadata.date": "Date",
  "metadata.documentType": "Document Type",
  "metadata.authNumber": "Auth Number",
  "metadata.remitent": "Remitent",
  "metadata.originCountry": "Origin Country",
  "metadata.clientIdType": "Client ID Type"
}

// referenceNumber?: string;
//   date?: string;
//   amount?: string;
//   clientName?: string;
//   documentType?: string;
//   authNumber?: string;
//   remitent?: string;
//   originCountry?: string;
//   clientIdType?: string;

interface DocumentError extends Error, Document { }

type ExtendedColumnDef = ColumnDef<Document> & {
  show?: boolean;
};

const DocumentList: React.FC = () => {

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})

  const [documents, setDocuments] = useState<Document[]>([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const { groupId } = useParams<{ groupId: string }>();
  const { currentUser } = useAuth();
  const [userRole, setUserRole] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserRole();
    fetchGroupDetails();
    fetchDocuments();
  }, [groupId]);

  const fetchUserRole = async () => {
    if (currentUser) {
      const userDoc = await getDocs(query(collection(firestore, 'users'), where('email', '==', currentUser.email)));
      if (!userDoc.empty) {
        setUserRole(userDoc.docs[0].data().role);
      }
    }
  };

  const fetchGroupDetails = async () => {
    if (groupId) {
      try {
        const groupRef = doc(firestore, 'documentGroups', groupId);
        const groupDoc = await getDoc(groupRef);
        if (groupDoc.exists()) {
          setGroupName(groupDoc.data().name);
        }
      } catch (error) {
        console.error('Error fetching group details:', error);
      }
    }
  };

  const columns: ExtendedColumnDef[] = [

    // Checkbox
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },

    // Document name
    {
      accessorKey: "filename",
      id: "filename",
      header: "MTCN document",
      cell: ({ row }) => (
        <>
          {row.getValue("filename") && (
            <div className="text-sm font-medium text-gray-900">{row.getValue(("metadata.clientName"))}</div>
          )}
          <div className="text-xs text-gray-500">{row.getValue("filename")}</div>
        </>
      ),
    },

    // Reference
    {
      accessorKey: "metadata.referenceNumber",
      id: "metadata.referenceNumber",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Reference
            <ArrowUpDown />
          </Button>
        )
      },
      cell: ({ row }) => <div>{row.getValue("metadata.referenceNumber")}</div>,
      show: true,
    },

    // Client Name
    {
      accessorKey: "metadata.clientName",
      id: "metadata.clientName",
      enableHiding: true,
      show: true,
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Client Name
            <ArrowUpDown />
          </Button>
        )
      },
      cell: ({ row }) => <div>{row.getValue("metadata.clientName")}</div>,
    },

    // Remitent
    {
      accessorKey: "metadata.remitent",
      id: "metadata.remitent",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Remitent
            <ArrowUpDown />
          </Button>
        )
      },
      cell: ({ row }) => <div>{row.getValue("metadata.remitent")}</div>,
    },

    // Date
    {
      accessorKey: "metadata.date",
      id: "metadata.date",
      header: () => <div className="text-center">Date</div>,
      cell: ({ row }) => {
        const dateValue = row.getValue("metadata.date");

        // Check if dateValue is null, undefined, or an empty object
        if (!dateValue || (typeof dateValue === 'object' && Object.keys(dateValue).length === 0)) {
          return <div className="text-right font-medium">-</div>;
        }

        try {
          // Explicitly convert to string first
          const dateString = dateValue.toString();
          const date = new Date(dateString);

          if (isNaN(date.getTime())) {
            return <div className="text-right font-medium">{dateString}</div>;
          }

          return <div className="text-right font-medium">{format(date, 'dd/MM/yy HH:mm:ss')}</div>;
        } catch (error) {
          console.error('Error formatting date:', error);
          return <div className="text-right font-medium">Invalid date {JSON.stringify(error)}</div>;
        }
      },
    },

    // Amount
    {
      accessorKey: "metadata.amount",
      id: "metadata.amount",
      header: () => <div className="text-right">Amount</div>,
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue("metadata.amount"))

        // Format the amount as a dollar amount
        const formatted = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(amount)

        return <div className="text-right font-medium">{formatted}</div>
      },
    },

    // Auth Number
    {
      accessorKey: "metadata.authNumber",
      id: "metadata.authNumber",
      header: () => <div className="text-right">Auth Number</div>,
      cell: ({ row }) => {
        return <div className="text-right font-medium">{row.getValue("metadata.authNumber")}</div>
      },
    },

    // Actions
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const mtcn = row.original

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuItem
                onClick={() => setSelectedDocument(mtcn)}
              >
                <EyeIcon className="h-5 w-5" />
                View document
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  const documentUrl = mtcn.url || mtcn.storageRef || '';
                  handlePrint({ pdfUrl: documentUrl });
                }}>
                <DownloadIcon className="h-5 w-5" />
                Download document
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              {userRole === 'admin' && (
                <AlertDialogDelete collectionName='documents' documentId={mtcn.id} buttonTitle='Delete document' onDocumentDeleted={fetchDocuments} />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]

  const table = useReactTable({
    data: documents,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return;

    if (!currentUser) {
      toast.error('Please sign in to upload files');
      return;
    }

    const files = Array.from(event.target.files);
    setUploading(true);
    const uploadPromises: Promise<Document>[] = [];

    // Create a loading toast that we'll update
    const loadingToastId = toast.loading(`Uploading ${files.length} file${files.length > 1 ? 's' : ''}...`);

    try {
      for (const file of files) {
        const uploadPromise = (async () => {
          try {
            // Upload file to Firebase Storage
            const storageRef = ref(storage, `files/${currentUser.uid}/${groupId}/${Date.now()}_${file.name}`);
            await uploadBytes(storageRef, file);

            // Get download URL
            const downloadUrl = await getDownloadURL(storageRef);

            // Extract PDF data
            let metadata = {};
            if (file.type === 'application/pdf') {
              try {
                metadata = await extractPDFData(downloadUrl);
              } catch (error) {
                console.error('Error extracting PDF data:', error);
              }
            }

            // Create document in Firestore
            const docRef = await addDoc(collection(firestore, 'documents'), {
              filename: file.name,
              size: file.size,
              uploadedAt: new Date(),
              userId: currentUser.uid,
              groupId: groupId,
              storageRef: storageRef.fullPath,
              url: downloadUrl,
              metadata: metadata
            });

            // Get the newly created document
            const newDocSnapshot = await getDoc(docRef);
            const newDoc = {
              id: newDocSnapshot.id,
              ...newDocSnapshot.data()
            } as Document;

            // Update the local state with the new document
            setDocuments(prevDocs => [newDoc, ...prevDocs]);

            return newDoc;
          } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
          }
        })();

        uploadPromises.push(uploadPromise);
      }

      // Wait for all uploads to complete
      const uploadResults = await Promise.all(uploadPromises);

      // Count successes and failures
      const successCount = uploadResults.length;

      const failedResults = uploadResults.filter(
        (result): result is DocumentError =>
          result instanceof Error || result === undefined
      );
      const failCount = failedResults.length;

      // Update the loading toast with the final result
      toast.dismiss(loadingToastId);
      if (successCount > 0) {
        toast.success(`Successfully uploaded ${successCount} file${successCount > 1 ? 's' : ''}`);
      } else {
        if (failCount > 0) {
          toast.error(`Failed to upload ${failCount} file${failCount > 1 ? 's' : ''}`);
        }
      }

      // Log any errors that occurred during upload
      failedResults.forEach(result => {
        console.error('Error uploading file:', result);
      });
    } catch (error) {
      console.error('Unexpected error during file uploads:', error);
      toast.error('An unexpected error occurred during file uploads');
    } finally {
      setUploading(false);
      if (event.target.form) {
        event.target.form.reset();
      }
    }
  };

  const handlePrint = ({pdfUrl}: {pdfUrl: string}) => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank')?.print();
    }
  };


  const formatDate = (date: Date | { seconds: number, nanoseconds: number } | any) => {
    if (!date) return 'N/A';

    // Handle Firestore Timestamp
    if (date && typeof date === 'object' && 'seconds' in date) {
      return format(new Date(date.seconds * 1000), 'MMM d, yyyy HH:mm');
    }

    // Handle regular Date object
    if (date instanceof Date) {
      return format(date, 'MMM d, yyyy HH:mm');
    }

    // Try to parse string date
    try {
      return format(new Date(date), 'MMM d, yyyy HH:mm');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  const fetchDocuments = async () => {
    if (!groupId || !currentUser) return;

    setLoading(true);
    try {
      const q = query(
        collection(firestore, 'documents'),
        where('groupId', '==', groupId)
      );
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          uploadedAt: data.uploadedAt?.toDate?.() || data.uploadedAt || new Date(),
        } as Document;
      });

      setDocuments(docs.sort((a, b) => {
        const dateA = a.uploadedAt instanceof Date ? a.uploadedAt : new Date(a.uploadedAt);
        const dateB = b.uploadedAt instanceof Date ? b.uploadedAt : new Date(b.uploadedAt);
        return dateB.getTime() - dateA.getTime();
      }));
    } catch (error) {
      console.error('Error fetching documents:', error);
      alert('Error fetching documents. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (documentId: string, storageRef: string) => {
    if (userRole !== 'admin') {
      alert('Only administrators can delete documents');
      return;
    }

    if (window.confirm('Are you sure you want to delete this document?')) {
      try {
        await deleteDoc(doc(firestore, 'documents', documentId));
        const fileRef = ref(storage, storageRef);
        await deleteObject(fileRef);
        setDocuments(documents.filter(doc => doc.id !== documentId));
      } catch (error) {
        console.error('Error deleting document:', error);
        alert('Failed to delete document');
      }
    }
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (

    <div className="w-full">
      <div className="flex flex-col items-start justify-between mb-4">
      <Breadcrumb className='mb-4'>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Groups</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>{groupName}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
        <h1 className="text-2xl font-semibold text-gray-900 flex items-center">
          Documents in <span className="ml-2 text-indigo-600">{groupName}</span>
        </h1>
        <p className="mt-2 text-sm text-gray-700">
          Manage documents in the {groupName} group
        </p>
      </div>
      <div className="flex items-center py-4">
        <Input
          placeholder="Filter documents"
          value={(table.getColumn("metadata.referenceNumber")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("metadata.referenceNumber")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns <ChevronDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {interfaceMappings[column.id] || column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>

      {selectedDocument !== null && selectedDocument?.url && (
        <PDFViewer
          url={selectedDocument.url}
          filename={selectedDocument.filename}
          isOpen={true}
          onClose={() => setSelectedDocument(null)}
        />
      )}

    </div>

    // <div className="px-4 sm:px-6 lg:px-8">
    //   <Toaster position="top-right" />
    //   <div className="sm:flex sm:items-center">
    //     <div className="sm:flex-auto">
    //       <button
    //         onClick={() => navigate('/')}
    //         className="mb-4 inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
    //       >
    //         <ArrowLeftIcon className="mr-2 h-4 w-4" />
    //         Back to Groups
    //       </button>
    //       <h1 className="text-2xl font-semibold text-gray-900 flex items-center">
    //         Documents in <span className="ml-2 text-indigo-600">{groupName}</span>
    //       </h1>
    //       <p className="mt-2 text-sm text-gray-700">
    //         Manage documents in the {groupName} group
    //       </p>
    //     </div>
    //     <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
    //       <div className="mt-4">
    //         <label
    //           htmlFor="file-upload"
    //           className={`
    //             relative cursor-pointer rounded-md bg-white font-medium text-blue-600 
    //             focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 
    //             focus-within:ring-offset-2 hover:text-blue-500
    //             ${uploading ? 'opacity-50 cursor-not-allowed' : ''}
    //           `}
    //         >
    //           <span>Upload files</span>
    //           <input
    //             id="file-upload"
    //             name="file-upload"
    //             type="file"
    //             className="sr-only"
    //             onChange={handleFileUpload}
    //             multiple
    //             accept=".pdf"
    //             disabled={uploading}
    //           />
    //         </label>
    //         {uploading && (
    //           <div className="mt-2 text-sm text-gray-500">
    //             Uploading files...
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   </div>

    //   {documents.length === 0 ? (
    //     <div className="text-center py-12">
    //       <p className="text-gray-500">No documents in this group yet.</p>
    //     </div>
    //   ) : (
    //     <div className="mt-8 flow-root">
    //       <div className="overflow-x-auto">
    //         <table className="min-w-full divide-y divide-gray-200">
    //           <thead className="bg-gray-50">
    //             <tr>
    //               <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
    //                 Name
    //               </th>
    //               <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
    //                 Reference
    //               </th>
    //               <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
    //                 Date
    //               </th>
    //               <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
    //                 Remitent
    //               </th>
    //               <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
    //                 Amount
    //               </th>
    //               <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
    //                 Origin
    //               </th>
    //               <th scope="col" className="relative px-6 py-3">
    //                 <span className="sr-only">Actions</span>
    //               </th>
    //             </tr>
    //           </thead>
    //           <tbody className="bg-white divide-y divide-gray-200">
    //             {documents.map((doc) => (
    //               <tr key={doc.id} className="hover:bg-gray-50">
    //                 <td className="px-6 py-4 whitespace-nowrap">
    //                   <div className="flex items-center">
    //                     <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
    //                     <div className="ml-4">
    //                       {doc.metadata?.clientName && (
    //                         <div className="text-sm font-medium text-gray-900">{doc.metadata.clientName}</div>
    //                       )}
    //                       <div className="text-xs text-gray-500">{doc.filename}</div>
    //                     </div>
    //                   </div>
    //                 </td>
    //                 <td className="px-6 py-4 whitespace-nowrap">
    //                   <div className="text-sm text-gray-900">{doc.metadata?.referenceNumber || '-'}</div>
    //                 </td>
    //                 <td className="px-6 py-4 whitespace-nowrap">
    //                   <div className="text-sm text-gray-900">{doc.metadata?.date || '-'}</div>
    //                 </td>
    //                 <td className="px-6 py-4 whitespace-nowrap">
    //                   <div className="text-sm text-gray-900">{doc.metadata?.remitent || '-'}</div>
    //                 </td>
    //                 <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
    //                   {doc.metadata?.amount ? `$${doc.metadata.amount}` : '-'}
    //                 </td>
    //                 <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
    //                   {doc.metadata?.originCountry || '-'}
    //                 </td>
    //                 <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
    //                   <div className="flex items-center space-x-3 justify-end">
    //                     <button
    //                       onClick={() => setSelectedDocument(doc)}
    //                       className="text-indigo-600 hover:text-indigo-900"
    //                     >
    //                       <EyeIcon className="h-5 w-5" />
    //                     </button>
    //                     <button
    //                       onClick={() => handleDelete(doc.id, doc.storageRef)}
    //                       className="text-red-600 hover:text-red-900"
    //                     >
    //                       <TrashIcon className="h-5 w-5" />
    //                     </button>
    //                   </div>
    //                 </td>
    //               </tr>
    //             ))}
    //           </tbody>
    //         </table>
    //       </div>
    //     </div>
    //   )}

    //   {selectedDocument && selectedDocument.url && (
    //     <PDFViewer
    //       url={selectedDocument.url}
    //       filename={selectedDocument.filename}
    //       isOpen={true}
    //       onClose={() => setSelectedDocument(null)}
    //     />
    //   )}
    // </div>
  );
};

export default DocumentList;
