import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { sendPasswordResetEmail, deleteUser, getAuth } from 'firebase/auth';
import { firestore } from '../config/firebase';
import { UserCircleIcon, TrashIcon, PencilIcon, KeyIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import AddUserModal from '../components/AddUserModal';

interface User {
  id: string;
  email: string;
  displayName: string;
  phoneNumber: string;
  role: string;
  emailVerified: boolean;
  lastLogin: string | null;
  createdAt: string;
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingUser, setEditingUser] = useState<string | null>(null);
  const [editData, setEditData] = useState({
    displayName: '',
    phoneNumber: '',
    role: ''
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [error, setError] = useState('');

  const fetchUsers = async () => {
    try {
      const usersCollection = collection(firestore, 'users');
      const snapshot = await getDocs(usersCollection);
      const usersList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as User));
      setUsers(usersList);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleDeleteUser = async (userId: string, userEmail: string) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        // Delete from Firestore
        await deleteDoc(doc(firestore, 'users', userId));
        
        // Delete from Authentication
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          await deleteUser(user);
        }

        setUsers(users.filter(user => user.id !== userId));
      } catch (error) {
        console.error('Error deleting user:', error);
        setError('Failed to delete user');
      }
    }
  };

  const handleUpdateUser = async (userId: string) => {
    try {
      const userRef = doc(firestore, 'users', userId);
      await updateDoc(userRef, {
        displayName: editData.displayName,
        phoneNumber: editData.phoneNumber,
        role: editData.role
      });

      setUsers(users.map(user => 
        user.id === userId 
          ? { ...user, ...editData }
          : user
      ));
      setEditingUser(null);
    } catch (error) {
      console.error('Error updating user:', error);
      setError('Failed to update user');
    }
  };

  const handleResetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(getAuth(), email);
      alert('Password reset email sent successfully');
    } catch (error) {
      console.error('Error sending reset password email:', error);
      setError('Failed to send password reset email');
    }
  };

  const startEditing = (user: User) => {
    setEditingUser(user.id);
    setEditData({
      displayName: user.displayName,
      phoneNumber: user.phoneNumber,
      role: user.role
    });
  };

  return (
    <>
      <div className="bg-white shadow-md rounded-lg">
        <div className="p-6">
          <div className="sm:flex sm:items-center sm:justify-between mb-6">
            <button
              onClick={() => setShowAddModal(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add New User
            </button>
          </div>

          {error && (
            <div className="mb-4 text-red-500 text-sm">
              {error}
            </div>
          )}

          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">User Info</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Contact</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                        <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {users.map((user) => (
                        <tr key={user.id}>
                          <td className="whitespace-nowrap px-3 py-4">
                            <div className="flex items-center">
                              <UserCircleIcon className="h-10 w-10 text-gray-400" />
                              <div className="ml-4">
                                {editingUser === user.id ? (
                                  <input
                                    type="text"
                                    value={editData.displayName}
                                    onChange={(e) => setEditData({ ...editData, displayName: e.target.value })}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                ) : (
                                  <>
                                    <div className="font-medium text-gray-900">{user.displayName}</div>
                                    <div className="text-gray-500">{user.email}</div>
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4">
                            {editingUser === user.id ? (
                              <input
                                type="tel"
                                value={editData.phoneNumber}
                                onChange={(e) => setEditData({ ...editData, phoneNumber: e.target.value })}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            ) : (
                              <div className="text-gray-900">{user.phoneNumber}</div>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4">
                            <div className="flex items-center">
                              {user.emailVerified ? (
                                <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                              ) : (
                                <XCircleIcon className="h-5 w-5 text-red-500 mr-2" />
                              )}
                              <span className={user.emailVerified ? 'text-green-800' : 'text-red-800'}>
                                {user.emailVerified ? 'Verified' : 'Not Verified'}
                              </span>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4">
                            {editingUser === user.id ? (
                              <select
                                value={editData.role}
                                onChange={(e) => setEditData({ ...editData, role: e.target.value })}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              >
                                <option value="user">User</option>
                                <option value="admin">Admin</option>
                                <option value="manager">Manager</option>
                              </select>
                            ) : (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                {user.role}
                              </span>
                            )}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div className="flex items-center justify-end space-x-2">
                              {editingUser === user.id ? (
                                <>
                                  <button
                                    onClick={() => handleUpdateUser(user.id)}
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={() => setEditingUser(null)}
                                    className="text-gray-600 hover:text-gray-900"
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    onClick={() => startEditing(user)}
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    <PencilIcon className="h-5 w-5" />
                                  </button>
                                  <button
                                    onClick={() => handleResetPassword(user.email)}
                                    className="text-yellow-600 hover:text-yellow-900"
                                  >
                                    <KeyIcon className="h-5 w-5" />
                                  </button>
                                  <button
                                    onClick={() => handleDeleteUser(user.id, user.email)}
                                    className="text-red-600 hover:text-red-900"
                                  >
                                    <TrashIcon className="h-5 w-5" />
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddUserModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
        onUserAdded={fetchUsers}
      />
    </>
  );
};

export default Users;
