import { TrashIcon } from "lucide-react"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "./ui/alert-dialog"
import { DropdownMenuItem } from "./ui/dropdown-menu"
import React from "react";
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../config/firebase';

  export function AlertDialogDelete({collectionName, buttonTitle, documentId, onDocumentDeleted}: {collectionName: string, buttonTitle: string, documentId: string, onDocumentDeleted: () => void}) {

    const [open, setOpen] = React.useState(false);

    const handleDeleteDocument = async () => {
          try {
            await deleteDoc(doc(firestore, collectionName, documentId));
            
            // Update files to remove group association
            const filesRef = collection(firestore, 'files');
            const filesSnapshot = await getDocs(query(filesRef, where('groupId', '==', documentId)));
            
            const updatePromises = filesSnapshot.docs.map(fileDoc => 
              updateDoc(doc(firestore, 'files', fileDoc.id), { groupId: null })
            );
            
            await Promise.all(updatePromises);
            onDocumentDeleted();
          } catch (error) {
            console.error('Error deleting group:', error);
          }
        };

    return (
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <TrashIcon className="h-5 w-5" />
            {buttonTitle}
        </DropdownMenuItem>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently remove your data from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setOpen(false)}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDeleteDocument}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }
  