import React from 'react';
import DocumentGroups from '../components/DocumentGroups';

const Dashboard: React.FC = () => {
  return (
    <div className="p-6">
      <div className="mb-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <h1 className="text-2xl font-bold text-gray-900">Groups</h1>
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg">
        <DocumentGroups />
      </div>
    </div>
  );
};

export default Dashboard;
