import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { XMarkIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

// Set worker source
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PDFViewerProps {
  url: string;
  filename: string;
  isOpen: boolean;
  onClose: () => void;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ url, filename, isOpen, onClose }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        const storage = getStorage();
        const fileRef = ref(storage, url);
        const downloadUrl = await getDownloadURL(fileRef);
        setPdfUrl(downloadUrl);
      } catch (error) {
        console.error('Error fetching PDF URL:', error);
      }
    };

    if (isOpen && url) {
      fetchPdfUrl();
    }

    return () => {
      setPdfUrl(null);
    };
  }, [url, isOpen]);

  function onDocumentLoadSuccess({ numPages }: PDFDocumentProxy) {
    setNumPages(numPages);
    setLoading(false);
  }

  const handlePrint = () => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank')?.print();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" onClick={onClose}></div>

        <div className="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500"
              onClick={onClose}
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
          </div>

          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {filename}
              </h3>
              {/* <button
                onClick={handlePrint}
                className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                <PrinterIcon className="w-5 h-5 mr-2" />
                Print
              </button> */}
            </div>

            <div className="mt-2">
              {loading && (
                <div className="flex justify-center items-center h-64">
                  <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
                </div>
              )}
              {pdfUrl && (
                <Document
                  file={pdfUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={
                    <div className="flex justify-center items-center h-64">
                      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
                    </div>
                  }
                  error={
                    <div className="flex justify-center items-center h-64 text-red-500">
                      Failed to load PDF file.
                    </div>
                  }
                >
                  <Page 
                    pageNumber={pageNumber} 
                    scale={1.0}
                    width={Math.min(window.innerWidth * 0.8, 800)}
                  />
                </Document>
              )}
              {numPages && (
                <div className="mt-4 text-center text-sm text-gray-600">
                  Page {pageNumber} of {numPages}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFViewer;
